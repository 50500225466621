<template><div><h1 id="disabling-write-protect-with-a-suzyqable" tabindex="-1"><a class="header-anchor" href="#disabling-write-protect-with-a-suzyqable"><span>Disabling write protect with a SuzyQable</span></a></h1>
<div class="custom-container tip"><p class="custom-container-title">TIP</p>
<p>This method is only available on CR50 devices.</p>
</div>
<div class="custom-container tip"><p class="custom-container-title">TIP</p>
<p>This method requires a SuzyQable, a chromeOS debug cable.</p>
</div>
<br>
<h2 id="step-1-enabling-closed-case-debugging-ccd" tabindex="-1"><a class="header-anchor" href="#step-1-enabling-closed-case-debugging-ccd"><span>Step 1: Enabling Closed Case Debugging (CCD)</span></a></h2>
<ol>
<li>Open VT-2 (<code v-pre>ctrl</code> + <code v-pre>alt</code> + <code v-pre>f2</code> (right arrow)).</li>
<li>Login as <code v-pre>root</code>.</li>
<li>Run <code v-pre>gsctool -a -o</code>.</li>
<li>You will be prompted to press the <code v-pre>PP</code> (physical presence) button several times. This is a fancy way of saying to press the power button. This process takes several minutes and ends with the message <code v-pre>PP Done!</code>.</li>
<li>The device will suddenly reboot and exit developer mode. Re-enable developer mode and continue with the instructions.</li>
</ol>
<h2 id="step-2-disable-write-protect" tabindex="-1"><a class="header-anchor" href="#step-2-disable-write-protect"><span>Step 2: Disable Write Protect</span></a></h2>
<ol>
<li>Open VT-2 (<code v-pre>ctrl</code> + <code v-pre>alt</code> + <code v-pre>f2</code> (right arrow)).</li>
<li>Login as <code v-pre>root</code>.</li>
<li>Plug in your cable</li>
<li>Run <code v-pre>ls /dev/ttyUSB*</code> to verify your cable is working. This should output 3 items, <code v-pre>ttyUSB0</code>, <code v-pre>ttyUSB1</code>, and <code v-pre>ttyUSB2</code>. If you do not see these items, try flipping the <code v-pre>usb-c</code> end upside-down, and try another <code v-pre>usb-c</code> port. If there is still no result, confirm your cable was assembled properly.</li>
<li>Run the following commands. This will disable hardware write protect.
<ul>
<li><code v-pre>echo &quot;wp false&quot; &gt; /dev/ttyUSB0</code></li>
<li><code v-pre>echo &quot;wp false atboot&quot; &gt; /dev/ttyUSB0</code></li>
</ul>
</li>
<li>Run <code v-pre>echo &quot;ccd reset factory&quot; &gt; /dev/ttyUSB0</code> to be able to unbrick using a suzyqable, if needed.</li>
<li>Run <code v-pre>gsctool -a -I</code> to verify you have opened all the CCD flags. The current value for all CCD flags should be set to Y/Always.</li>
<li>Verify <code v-pre>crossystem wpsw_cur</code> returns <code v-pre>0</code>.</li>
<li>Reboot.</li>
</ol>
</div></template>


